import React from 'react';
import { getDocumentFields } from 'utils/helper';
import cn from 'classnames';
import styles from './styles.module.scss';

const Card = ({ data }) => {
  const { contentModules: [cardData] } = getDocumentFields(data, [
    'contentModules',
  ]);

  const {
    title,
    description,
    contentList: items,
  } = getDocumentFields(
    cardData,
    [
      'title',
      'description',
      'contentList',
    ],
  );

  const titleWords = title.split('|');

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <article className={styles.card}>
          <div className={styles['card-sidebar']}>
            {title && (
              <h2 className={styles['card-title']}>
                {titleWords.map((word, i) => (
                  <span
                    key={i}
                    className={cn({ [styles.lastWord]: i + 1 === titleWords?.length })}
                  >
                    {word}
                  </span>
                ))}
              </h2>
            )}
            {description && (
              <p className={styles['card-description']}>{description}</p>
            )}
          </div>
          <div className={styles['card-body']}>
            <ul className={styles['card-list']}>
              {(items || []).map((item, i) => (
                <li
                  key={item}
                  className={cn(styles['list-item'], styles[`list-item-${i + 1}`])}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Card;
